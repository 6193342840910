.primary-box{
    height: $primary-box;
}

.secondary-box{
    height: $secondary-box;
}

.tertiary-box{
    height: $tertiary-box;
}

.primary-font-size{
    font-size: $primary-font-size !important;
}

.secondary-font-size{
    font-size: $secondary-font-size;
}

.tertiary-font-size{
    font-size: $tertiary-font-size;
}

//add this class to adjust the width percentage
.w {
    &-50 {
        width: 50%;
    }
    &-60 {
        width: 60%;
    }
    &-70 {
        width: 70%;
    }
    &-80 {
        width: 80%;
    }
    &-90 {
        width: 90%;
    }
}