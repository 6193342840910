.itineraryTbl, .mmdTbl{
    min-height: calc(80vh - 10px);
    height: 100%;
    width: 100%;
    margin-top: 10px;
    background-color: $primary-white;
    border-radius: 1em;
    overflow-x: auto;

    table{
        height: 1vh;
        width: 100%;
        table-layout: fixed;
        // background-color: $primary-white;
        // border-radius: 1em;
        cursor: pointer;
        thead{
            border-bottom: 1px solid $tertiary-green;
            font-size: $primary-font-size;
            color: $primary-green;
            tr{

                th{
                    height: 60px;
                    width: 194px;
                    padding-left: 20px;
                    color: $primary-green;
                    font-weight: normal;

                    span {
                        padding-left: 5px;
                    }

                    .sorted {
                        color: $quinary-color;
                    }
                    
                    .no-sort{
                        padding-left: 17px;
                    }
                }            
            }
        }
        
        tbody{
            font-size: $primary-font-size;
            color: $secondary-color;

            .table {
                &-no-data {
                    color: $primary-color;
                    position: absolute;
                    left: 60%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                }
            }

            tr{
                td{
                    height: 60px;
                    width: 20%;
                    padding-left: 37px;
                    white-space: normal;
                    word-wrap: break-word;
                }
                
                .upd-loc {
                    color: $tertiary-color;
                }
            }

            tr:hover {
                background-color: $primary-selected;
            }
        }
        tfoot{
            tr{
                td{
                    padding-left: 27px;
                }
            }
        }
    }
}