// input, textarea {
//     font-family: 'Open Sans', sans-serif;
//     width: 100%;
//     padding: $padding;
//     background-color: $primary-white;
//     border: 1px solid $tertiary-white;
//     border-radius: 1.33em;
//     outline: none;
//     margin: 0.33em 0em 0.33em 0em;
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
// -webkit-appearance: none;
// margin: 0;
// }

// /* Firefox */
// input[type=number], input[type=date] {
// -moz-appearance:textfield;
// }

// .primary-input {
//     border: solid 2px $primary-theme;
// }

// .secondary-input {
//     border: solid 1px $primary-theme;
// }

// input[type=radio]{
//     width: initial;
//     transform: scale(1.3);
// }

// .input-normal {
//     input:disabled, textarea:disabled {
//         border: solid 1px $tertiary-white !important;
//         border-radius: 1.33em;
//         background-color: $tertiary-white;
//         color: $primary-black;
//     }
//     textarea:disabled {
//         border-radius: 1.33em;
//     }
    
//     input:focus, textarea:focus {
//         border: solid 1px $primary-color !important;
//     }
    
// }
input{
    
    &:focus{
        outline: none;
    }
}
.searchBar{
    margin: 20px 0px 20px 20px;
    .searchItinerary{
        background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
        height: 60px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $tertiary-green;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $tertiary-green;
        opacity: 1;
    }
}

.mmdSearchBar{
    margin: 20px 0px 20px 0px;
    .searchItinerary{
        background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
        height: 60px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $tertiary-green;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $tertiary-green;
        opacity: 1;
    }
}

.mmdTextBox{
    margin: 20px 0px 20px 0px;
    .txtBox{
        //background: url("../../../img/icons/icon-search.svg") no-repeat scroll 30px 19px;
        height: 30px;
        width: 380px;
        padding: 20px 0px 20px 60px;
        border: 1px solid $tertiary-green;
        border-radius: 1.875em;
        background-color: $primary-white !important;
    
        // font: italic normal 300 20px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: $tertiary-green;
        opacity: 1;
    }
}

::placeholder {
    color: $tertiary-green;
}