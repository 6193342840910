// COLORS ============================
// theme
$primary-theme: #EFF7F5;

// black
$primary-black: #3B3B3B;

// white
$primary-white: #FFFFFF;

//gray
$primary-gray: #919191;
$secondary-gray: #3B3B3B80;
$tertiary-gray: #BDBDBD;

//green
$primary-green: #2C906B;
$secondary-green: #309A74;
$tertiary-green: #A0D7C7;
$quaternary-green: #2C906B1A;

//font color
$primary-color: #919191;
$secondary-color: #3B3B3B;
$tertiary-color: #F88FAA;
$quaternary-color: #EFF7F5;
$quinary-color: #EBCA90;

//selected color
$primary-selected: #F7EAD3;

// FONTS ============================
$primary-font-size: 16px;
$secondary-font-size: 20px;
$tertiary-font-size: 30px;
$quaternary-font-size: 12px;

// BOX HEIGHT =======================
$primary-box: 7em;
$secondary-box: 5em;
$tertiary-box: 2em;

// DESIGN UTILS
$border-radius: 0.3em;
$padding-button: 1em;
$padding: 10px 15px 10px 15px;


// Table font size
$table-primary-font-size: 15px;
$table-header-sort: 9px;

// Table paddings
$table-primary-top-bottom: 15px;
$table-primary-left-right: 10px;
$table-primary-padding: 15px 10px;